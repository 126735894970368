import React, { useEffect } from 'react';
import {Redirect } from 'react-router-dom';
import { logged } from '../../auth/helpers';
import { useDispatch } from 'react-redux';
import SignInForm  from '../../components/auth/SignInForm'

const Signin = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		document.getElementById('body').classList.add('loginContainer');
		dispatch({
			type: 'CLEAR_REDUX',
		});
	}, []);

	return (
		<>
			{logged() ? <Redirect to="/" /> : null}

			<main className="form-signin">
				<div className="bg"></div>
				<div className="container signIn">
					<div className="row w-100"/>
					<div className="row w-100">
						<div className="col-md-12 col-12">
							<img className="logInLogoUno" src="/assets/img/logo_vertical.svg" alt=""/>
						</div>
						<div className="col-xl-4 col-lg-6 col-md-6 col-12 mx-auto h-100">
							<SignInForm/>
						</div>
					</div>
					<div className="row w-100">
						<div className="col-md-12 col-12">
							<img className="logInLogoDos" src="/assets/img/logo_viacottone.svg" width="100%" alt="Logo Viacotone"/>
						</div>
					</div>
				</div>
			</main>
		</>
	)
}

export default Signin;
